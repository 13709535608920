import React, { useRef, useEffect, useState } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { WhatsAppOutlined} from '@ant-design/icons';
import moment from "moment";
import "./style.css";
import {
  Button,
  DatePicker,
  Radio,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Select,
} from "antd";
import {
  AddAction,
  EditAction,
  MemberDetailAction,
  initializeState,
} from "../../Action/Member";
const { Title } = Typography;
const { Option } = Select;

// Action
const mapDispatch = (dispatch) => {
  return {
    addData: (params) => dispatch(AddAction(params)),
    editData: (id, payload, navigate) =>
      dispatch(EditAction(id, payload, navigate)),
    SingalMemberDetail: (id) => dispatch(MemberDetailAction(id)),
    resetState: () => dispatch(initializeState()),
  };
};

const mapState = (props) => ({
  SelectLoader: props?.MemberReducer?.isloading,
  // ResponseCode: props?.MemberReducer?.ResponseCode,
  // error: props?.MemberReducer?.error,
  // errorDetails: props?.MemberReducer?.errorDetails,
  success: props?.MemberReducer?.success,
  successDetails: props?.MemberReducer?.successDetails,
  MemberDetails: props?.MemberReducer?.MemberDetails,
});

function Addmember(store) {
  const [form] = Form.useForm();
  const formRef = useRef();
  const { id } = useParams();
  const {nextId } = useParams();
  const [memberNumber, setMemberID] = useState();
  const navigate = useNavigate();
  // Edit
  useEffect(() => {
    id && store?.SingalMemberDetail(id);
    // eslint-disable-next-line
  }, [id]);
  // Edit
  useEffect(() => {
    if (id) {
      if (store?.MemberDetails) {
        form.setFieldsValue({
          firstname:
            store?.MemberDetails?.firstname?.split(".")?.length > 1
              ? store?.MemberDetails?.firstname?.split(".")?.[1]?.toString()
              : store?.MemberDetails?.firstname?.split(".")?.[0]?.toString(),
          prefix:
            store?.MemberDetails?.firstname?.split(".")?.length > 1
              ? store?.MemberDetails?.firstname?.split(".")?.[0]?.toString() +
              "."
              : "Mr.",

          addressLine1: store?.MemberDetails?.addressLine1,
          addressLine2: store?.MemberDetails?.addressLine2,
          addressLine3: store?.MemberDetails?.addressLine3,
          city: store?.MemberDetails?.city,
          district: store?.MemberDetails?.district,
          education: store?.MemberDetails?.education,
          email: store?.MemberDetails?.email,
          gender: store?.MemberDetails?.gender,
          group: store?.MemberDetails?.group,
          internalGroupId: store?.MemberDetails?.internalGroupId,
          joiningDate: moment(
            new Date(store?.MemberDetails?.joiningDate)?.toLocaleDateString(),
            "DD-MM-YYYY"
          ),
          birthDate: moment(
            new Date(store?.MemberDetails?.birthDate)?.toLocaleDateString(),
            "DD-MM-YYYY"
          ),
          landmark: store?.MemberDetails?.landmark,
          lastname: store?.MemberDetails?.lastname,
          maritalStatus: store?.MemberDetails?.maritalStatus,
          middlename: store?.MemberDetails?.middlename,
          mobileNumber1: store?.MemberDetails?.mobileNumber1,
          mobileNumber2: store?.MemberDetails?.mobileNumber2,
          pincode: store?.MemberDetails?.pincode,
          state: store?.MemberDetails?.state,
          watsappMobileNumber: store?.MemberDetails?.watsappMobileNumber,
          membership_status: store?.MemberDetails?.membershipStatus,

          nominationName1: store?.MemberDetails?.nomination?.name,
          nominee_dob_1: moment(
            new Date(
              store?.MemberDetails?.nomination?.birthdate
            )?.toLocaleDateString(),
            "DD-MM-YYYY"
          ),
          nominee_mobile_1: store?.MemberDetails?.nomination?.mobileNumber,
          nominationMemberRelation1: store?.MemberDetails?.nomination?.relation,
          memberId1: store?.MemberDetails?.nomination?.memberId,
          nominationName2: store?.MemberDetails?.nomination2?.name,
          nominee_dob_2: moment(
            new Date(
              store?.MemberDetails?.nomination2?.birthdate
            )?.toLocaleDateString(),
            "DD-MM-YYYY"
          ),
          nominee_mobile_2: store?.MemberDetails?.nomination2?.mobileNumber,
          nominationMemberRelation2:
            store?.MemberDetails?.nomination2?.relation,
          memberId2: store?.MemberDetails?.nomination2?.memberId,
          member_status: store?.MemberDetails?.memberStatus || "NA",
        });
        setMemberID(store?.MemberDetails?.memberNo);
        console.log(memberNumber);

      }
    }
    // eslint-disable-next-line
  }, [store?.MemberDetails, id]);

  // useEffect(() => {
  //   if (store.error && store?.errorDetails?.response?.status !== 400) {
  // }, [store.error]);

  useEffect(() => {
    if (store?.success && store?.successDetails?.data?.message) {
      console.log("here-succc");
      form.resetFields();
      store.resetState();
    }

    // eslint-disable-next-line
  }, [store?.success && store?.successDetails?.data?.message]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleFormSubmit(params) {
    let payload = {
      memberId1: params?.memberId1,
      memberId2: params?.memberId2,
      firstname: params?.prefix + params?.firstname,
      middlename: params?.middlename,
      lastname: params?.lastname,
      gender: params?.gender,
      addressLine1: params?.addressLine1,
      addressLine2: params?.addressLine2,
      addressLine3: params?.addressLine3,
      landmark: params?.landmark,
      city: params?.city,
      district: params?.district,
      pincode: params?.pincode,
      state: params?.state,
      group: params?.group,
      internalGroupId: params?.internalGroupId,
      watsappMobileNumber: params?.watsappMobileNumber,
      maritalStatus: params?.maritalStatus,
      education: params?.education,
      membership_status: params?.membership_status,
      joiningDate: params?.joiningDate,
      mobileNumber1: params?.mobileNumber1,
      mobileNumber2: params?.mobileNumber2,
      nominationName1: params?.nominationName1,
      nominationName2: params?.nominationName2,
      nominee_dob_1: params?.nominee_dob_1,
      nominee_dob_2: params?.nominee_dob_2,
      nominee_mobile_1: params?.nominee_mobile_1,
      nominee_mobile_2: params?.nominee_mobile_2,
      memberNo: params?.memberNo,
      nominationMemberRelation1: params?.nominationMemberRelation1,
      nominationMemberRelation2: params?.nominationMemberRelation2,
      email: params?.email,
      birthDate: params?.birthDate,
      memberStatus: params?.member_status,
    };
    console.log(payload, "------");
    if (id) {
      store.editData(id, payload, navigate);
    } else {
      store.addData(payload);
    }
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }} className="prefixSelect">
        <Option value="Mr.">Mr.</Option>
        <Option value="Mrs.">Mrs.</Option>
        <Option value="Miss.">Miss.</Option>
      </Select>
    </Form.Item>
  );

  return (
    <SiteLayout>
      <div className="subscription-container center-content">
        <Row gutter={2}>
          <Col
            xxl={16}
            xl={16}
            lg={22}
            md={22}
            sm={24}
            xs={24}
            style={{ margin: "0 auto" }}
          >
            <div className="headingDiv">
                <h2 style={{ textAlign: "start", margin: "0px", marginBottom:"18px" }}>
                  Member {(id && "Edit") || "Add"}
                </h2>
            </div>
            <div
              style={{ padding: "30px" }}
              className="checkout-form-container gig-sure-card-wrapper"
            >
              <Form
                scrollToFirstError={true}
                onFinish={handleFormSubmit}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                form={form}
                name="addmembers"
                autoComplete="off"
                ref={formRef}
                // onValuesChange={onChangesFormValues}
                initialValues={{
                  prefix: "Mr.",
                  own_property: ["none"],
                  primary_health: "none",
                }}
              >
                <Row className="mt-1">
                  <Col span={12}>
                    <Title level={4}>Member information</Title>
                  </Col>
                  <Col span={12}>
                    <Title level={4}>Member Number : {nextId ? Number(nextId) + 1 : memberNumber}</Title>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="firstname"
                      label="First name"
                      className="field-with-prefix"
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                        {
                          pattern: /^([^0-9]*)$/,
                          message: "Please Insert Valid First name",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        autoComplete="off"
                        placeholder="First name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="middlename"
                      label="Middle name"
                      rules={[
                        {
                          required: true,
                          message: "Middle name is required",
                        },
                        {
                          pattern: /^([^0-9]*)$/,
                          message: "Please insert valid middle name",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Middle name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="lastname"
                      label="Last name"
                      rules={[
                        {
                          required: true,
                          message: "Last name is required",
                        },
                        {
                          pattern: /^([^0-9]*)$/,
                          message: "Please Insert valid lirst name",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Last name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Email is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Email"
                      />
                    </Form.Item>
                  </Col>

                  {/* here */}
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="birthDate"
                      label="Birth date"
                      rules={[
                        {
                          required: true,
                          message: "Birth date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Birth date"
                        disabledDate={(current) => current && current.isAfter(new Date())}
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                        className="form-input-border"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Gender is required",
                        },
                      ]}
                    >
                      <Radio.Group size="large">
                        <Radio value="Male">Male</Radio>
                        <Radio value="Female">Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col span={24}>
                    <Title level={4}>Member contact details</Title>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="addressLine1"
                      label="Address line 1"
                      rules={[
                        {
                          required: true,
                          message: "Address line 1 is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Address line 1"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="addressLine2"
                      label="Address line 2"
                      rules={[
                        {
                          required: true,
                          message: "Address line 2 is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Address line 2"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="addressLine3"
                      label="Address line 3"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Address line 3"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="landmark"
                      label="Landmark"
                      rules={[
                        {
                          required: true,
                          message: "Landmark is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Landmark"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="city"
                      label="City / At 2 Post"
                      rules={[
                        {
                          required: true,
                          message: "City / At 2 Post is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="City / At 2 Post"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="district"
                      label="District"
                      rules={[
                        {
                          required: true,
                          message: "District is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="District"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: "State is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="State"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="pincode"
                      label="Pin code"
                      rules={[
                        {
                          required: true,
                          message: "Pin Code is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid pin code",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Pin Code"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="group"
                      label="Group id"
                      rules={[
                        {
                          required: true,
                          message: "Group id is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid group id",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Group id"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="internalGroupId"
                      label="Internal Group id"
                      rules={[
                        {
                          required: true,
                          message: "Internal group id is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid internal group id",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Internal Group id"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="mobileNumber1"
                      label="SMS Mobile number"
                      rules={[
                        {
                          required: true,
                          message: "Mobile number 1 is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="SMS Mobile number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="mobileNumber2"
                      label="Mobile number 2"
                      rules={[
                        {
                          required: true,
                          message: "Mobile number 2 is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid Mobile Number",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Mobile number 2"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <WhatsAppOutlined />
                    <Form.Item
                      name="watsappMobileNumber"
                      label="Whatsapp number"
                      rules={[
                        {
                          required: true,
                          message: "Whatsapp Mobile number is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Whatsapp mobile number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="joiningDate"
                      label="Joining date"
                      rules={[
                        {
                          required: true,
                          message: "Joining date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Joining date"
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                        className="form-input-border"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col span={24}>
                    <Title level={4}>Nomination Details 1</Title>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="nominationName1"
                      label="Nominee name"
                      rules={[
                        {
                          required: true,
                          message: "Nominee name is required",
                        },
                        {
                          pattern: /^([^0-9]*)$/,
                          message: "Please Insert valid nominee name",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Nominee name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="memberId1"
                      label="Member id"
                      rules={[
                        {
                          required: true,
                          message: "Member id is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid member id",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Member id"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      className="droupdownclass"
                      name="nominationMemberRelation1"
                      label="Nominee relation"
                      rules={[
                        {
                          required: true,
                          message: "Nominee relation is required",
                        },
                      ]}
                    >
                      <Select align="left" placeholder="Nominee relation">
                        <Option value="Husband">Husband</Option>
                        <Option value="Wife">Wife</Option>
                        <Option value="Son">Son</Option>
                        <Option value="Daughter">Daughter</Option>
                        <Option value="Father">Father</Option>
                        <Option value="Mother">Mother</Option>
                        <Option value="Brother">Brother</Option>
                        <Option value="Sister">Sister</Option>
                        <Option value="Grandfather">Grandfather</Option>
                        <Option value="Grandmother">Grandmother</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="nominee_dob_1"
                      label="Nominee date of birth"
                      rules={[
                        {
                          required: true,
                          message: "Nominee date of birth is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="form-input-border"
                        disabledDate={(current) => current && current.isAfter(new Date())}
                        placeholder="Nominee date of birth "
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col spna={24}>
                  <Form.Item
                          name="nominee_mobile_1"
                          label="Nominee Mobile number"
                          rules={[
                            {
                              required: true,
                              message: "Nominee 1 Mobile number is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert valid Mobile Number",
                            },
                          ]}
                        >
                          <Input
                            className="form-input-border"
                            autoComplete="off"
                            placeholder="Nominee Mobile number"
                          />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col span={24}>
                    <Title level={4}>Nomination Details 2</Title>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="nominationName2"
                      label="Nominee name"
                      rules={[
                        {
                          required: true,
                          message: "Nominee name is required",
                        },
                        {
                          pattern: /^([^0-9]*)$/,
                          message: "Please Insert valid nominee name",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Nominee name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="memberId2"
                      label="Member id"
                      rules={[
                        {
                          required: true,
                          message: "Member id is required",
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: "Please insert valid member id",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Member id"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      className="droupdownclass"
                      name="nominationMemberRelation2"
                      label="Nominee relation"
                      rules={[
                        {
                          required: true,
                          message: "Nominee relation is required",
                        },
                      ]}
                    >
                      <Select align="left" placeholder="Nominee relation">
                        <Option value="Husband">Husband</Option>
                        <Option value="Wife">Wife</Option>
                        <Option value="Son">Son</Option>
                        <Option value="Daughter">Daughter</Option>
                        <Option value="Father">Father</Option>
                        <Option value="Mother">Mother</Option>
                        <Option value="Brother">Brother</Option>
                        <Option value="Sister">Sister</Option>
                        <Option value="Grandfather">Grandfather</Option>
                        <Option value="Grandmother">Grandmother</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="nominee_dob_2"
                      label="Nominee date of birth"
                      rules={[
                        {
                          required: true,
                          message: "Nominee date of birth is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="form-input-border"
                        disabledDate={(current) => current && current.isAfter(new Date())}
                        placeholder="Nominee date of birth "
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col spna={24}>
                  <Form.Item
                          name="nominee_mobile_2"
                          label="Nominee Mobile number"
                          rules={[
                            {
                              required: true,
                              message: "Nominee 2 Mobile number is required",
                            },
                            {
                              pattern: /^(?:\d*)$/,
                              message: "Please insert valid Mobile Number",
                            },
                          ]}
                        >
                          <Input
                            className="form-input-border"
                            autoComplete="off"
                            placeholder="Nominee Mobile number"
                          />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Title level={4}> Other information</Title>
                  </Col>
                </Row>

                <Row gutter={10}>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="education"
                      label="Education"
                      rules={[
                        {
                          required: true,
                          message: "Education is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Education"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      className="droupdownclass"
                      name="maritalStatus"
                      label="Marital status"
                      rules={[
                        {
                          required: true,
                          message: "Marital status is required",
                        },
                      ]}
                    >
                      <Select align="left" placeholder="Marital status">
                        <Option value="Married">Married</Option>
                        <Option value="Unmarried">Unmarried</Option>
                        <Option value="Divorced">Divorced</Option>
                        <Option value="Widowed">Widowed</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="membership_status"
                      label="Membership status"
                      rules={[
                        {
                          required: true,
                          message: "Membership status is required",
                        },
                      ]}
                    >
                      <Input
                        className="form-input-border"
                        autoComplete="off"
                        placeholder="Membership status"
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>

                    <Form.Item
                      name="member_status"
                      label="Member status"
                      rules={[
                        {
                          required: true,
                          message: "Member status is required",
                        },
                      ]}
                    >

                      <Select style={{
                        width: "100%",
                      }}
                        autoComplete="off"
                        placeholder="Membership status"
                      >
                        <Option value="ACTIVE">ACTIVE</Option>
                        <Option value="PENDING">PENDING</Option>
                        <Option value="DFC-DONE">DFC-DONE</Option>
                        <Option value="DFC-REMAIN">DFC-REMAIN</Option>
                      </Select>
                    </Form.Item>

                  </Col>

                </Row>

                <Row>
                  <Col span={24} style={{ textAlign: "end" }}>
                    <Form.Item className="submit">
                      <Button
                        size="large"
                        className="bg-primary "
                        type="success"
                        htmlType="submit"
                      // loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(Addmember);
