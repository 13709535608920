import React, { useState, useEffect } from "react";
import { LogoutAdmin } from "../../../Action/AdminAuth";
import { CollapsedMenuAction } from "../../../Action/SiteSetting"
import { connect } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Layout, Menu, Tag } from "antd"; // message,
// import moment from "moment";
import "./sitelayout.css";
import { SiteConstants } from "../../../Config/SiteConstants";
import { Helmet } from "react-helmet"
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UsergroupAddOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

const mapDispatch = (dispatch) => {
  return {
    LogoutDispatch: (navigate) => dispatch(LogoutAdmin(navigate)),
    CollapsedMenuDispatch: (para) => dispatch(CollapsedMenuAction(para)),
  };
};

// State
const mapState = (props) => ({
  SiteSetting: props.SiteSettingReducer,
});

function SiteLayout(store) {
  const location = useLocation();
  const navigate = useNavigate();
  const { Header, Sider, Content, Footer } = Layout;
  const [current, setCurrent] = useState(location.pathname);
  const IsCollapsed = store.SiteSetting.isCollapsedMenu;
  const [collapsed, setCollapsed] = useState(
    IsCollapsed === true ? true : false
  );

  // useEffect(() => {
  //   let BearerToken = localStorage.getItem("Bearer");
  //   let UserName = localStorage.getItem("USER_NAME");

  //   if (BearerToken === null || UserName === null) {
  //     message.error("Your session is expired, please login again");
  //     store.LogoutDispatch(navigate);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // 1 Min After CHECK
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     let DateTimeToken = localStorage.getItem("DateTimeToken");
  //     let CurrentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

  //     if (CurrentDateTime > DateTimeToken) {
  //       message.error("Your session is expired, please login again");
  //       store.LogoutDispatch(navigate);
  //     }
  //   }, 60000);
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    store.CollapsedMenuDispatch(collapsed);
    // eslint-disable-next-line
  }, [collapsed]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  function handleClick(e) {
    setCurrent(e.key);
  }

  function Logout() {
    store.LogoutDispatch(navigate);
  }

  const CustomTitle = () => {
    if (current === "/dashboard") {
      return `Dashboard - ${SiteConstants.site_name}`;
    }

    if (current === "/members") {
      return `Members - ${SiteConstants.site_name}`;
    }

    if (current === "/add-member") {
      return `Add Members - ${SiteConstants.site_name}`;
    }

    if (current.includes("/edit-member")) {
      return `Edit Member - ${SiteConstants.site_name}`;
    }

    if (current.includes("/edit-death")) {
      return `Edit Death - ${SiteConstants.site_name}`;
    }

    if (current.includes("/memberdetail")) {
      return `Member Detail - ${SiteConstants.site_name}`;
    }

    if (current.includes("/fees")) {
      return `Member Fees - ${SiteConstants.site_name}`;
    }

    if (current.includes("/death")) {
      return `Death - ${SiteConstants.site_name}`;
    }

    if (current.includes("/dfc-list")) {
      return `DFC - ${SiteConstants.site_name}`;
    }

    if (current.includes("/add-death")) {
      return `Add Death - ${SiteConstants.site_name}`;
    }

    if (current.includes("/setting")) {
      return `Setting - ${SiteConstants.site_name}`;
    }

    if (current.includes("/transactions")) {
      return `Transactions - ${SiteConstants.site_name}`;
    }
    if (current.includes("/report")) {
      return `Report - ${SiteConstants.site_name}`;
    }
    if (current.includes("/dfc-pending")) {
      return `DFC Pending - ${SiteConstants.site_name}`;
    }
    if (current.includes("/add-transactions")) {
      return `Add Transactions - ${SiteConstants.site_name}`;
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>{CustomTitle()}</title>
      </Helmet>

      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Link to="/dashboard">
          <div className="logo" />
        </Link>

        <Menu
          theme="dark"
          onClick={handleClick}
          selectedKeys={[current]}
          mode="inline"
          defaultSelectedKeys={["dashboard"]}
        >
          <Menu.Item
            key="dashboard"
            className={current === "/dashboard" && "activeClass"}
            icon={<DashboardOutlined />}
          >
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item
            key="members"
            className={current === "/members" && "activeClass"}
            icon={<UsergroupAddOutlined />}
          >
            <Link to="/members">Members</Link>
          </Menu.Item>
          {/* changes start ....... */}

          <Menu.Item
            key="DFC Fees"
            className={current === "/fees" && "activeClass"}
            icon={<DollarCircleOutlined />}
          >
            <Link to="/fees">DFC Fees</Link>
          </Menu.Item>

          <Menu.Item
            key="Death Information"
            className={current === "/death" && "activeClass"}
            icon={<InfoCircleOutlined />}
          >
            <Link to="/death">Death Info</Link>
          </Menu.Item>

          <Menu.Item
            key="Death Information"
            className={current === "/dfc-list" && "activeClass"}
            icon={<InfoCircleOutlined />}
          >
            <Link to="/dfc-list">DFC list</Link>
          </Menu.Item>

          <Menu.Item
            key="Transactions"
            className={current === "/transactions" && "activeClass"}
            icon={<DollarCircleOutlined />}
          >
            <Link to="/transactions">Transactions</Link>
          </Menu.Item>
          <Menu.Item
            key="Report"
            className={current === "/report" && "activeClass"}
            icon={<DollarCircleOutlined />}
          >
            <Link to="/report">Report</Link>
          </Menu.Item>

          <Menu.Item
            key="DFC Pending"
            className={current === "/dfc-pending" && "activeClass"}
            icon={<DollarCircleOutlined />}
          >
            <Link to="/dfc-pending">DFC Pending</Link>
          </Menu.Item>

          <Menu.Item
            key="Setting"
            className={current === "/setting" && "activeClass"}
            icon={<SettingOutlined />}
          >
            <Link to="/setting">Setting</Link>
          </Menu.Item>

          {/* changes end ....... */}
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={() => Logout()}
          >
            <Link to="/">Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        {/* this is the header part */}
        <Header
          className="site-layout-background site-layout-header"
          style={{ padding: 0 }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div className="topnav-right">
            <Tag color="default">Welcome Admin</Tag>
          </div>
        </Header>

        {/* this is compontent part */}
        <Content className="site-layout-background site-layout-content">
          <div className="mainDiv">{store.children}</div>
        </Content>

        {/* this is the footer part */}
        <Footer className="site-layout-footer">
          © {new Date().getFullYear()} {SiteConstants.site_name} · All Right
          Reserved
        </Footer>
      </Layout>
    </Layout>
  );
}

export default connect(mapState, mapDispatch)(SiteLayout);
