import React, { useEffect, useState } from "react";
import Headernew from "../Reusable/Main-Layput/Headernew";
import Footernew from "../Reusable/Main-Layput/Footernew";
import { CheckUserTransaction } from "../../Action/UserTransaction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { Button, Input, message, Select } from "antd"; // Added Select import
import UserPaymentCheck from "./UserPaymentCheck";

function PaymentPage() {
  const dispatch = useDispatch();

  const { TransactionList, isLoading } = useSelector((state) => ({
    TransactionList: state?.UserTransactionReducer?.TransactionList,
    isLoading: state?.UserTransactionReducer?.isLoading,
  }));

  const userId = localStorage.getItem("_id");

  const [groupId, setGroupId] = useState("");
  const [dfcId, setDfcId] = useState("");  // State for selected dfcId
  const [payableTotalAmount, setPayableTotalAmount] = useState(0);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [paymentCharges, setPaymentCharges] = useState(0);
  const [finalPayment, setFinalPayment] = useState(0);
  const [firstDfcId, setFirstDfcId] = useState("");  // Store first DFC ID

  useEffect(() => {
    handleSearch();
  }, [dispatch]);

  useEffect(() => {
    setPaymentCharges(payableTotalAmount * 0.0225);
    setFinalPayment(payableTotalAmount + payableTotalAmount * 0.0225);
  }, [payableTotalAmount]);

  useEffect(() => {
    if (TransactionList && TransactionList.length > 0) {
      const firstDfc = TransactionList[0]?.dfcNumber;
      setFirstDfcId(firstDfc);  
      setDfcId(firstDfc); 
    }
  }, [TransactionList]);

  const handleSearch = () => {
    const params = {};
    if (groupId && dfcId) {
      params.internalGroupId = groupId;
    } else if (!groupId && !dfcId) {
      params.userId = userId;
    } else {
      message.error("Please provide both Group ID and DFC Number");
      return;
    }

    dispatch(CheckUserTransaction(params));
  };

  const toggleMemberSelection = (transactionId, amount) => {
    const isSelected = selectedTransactions.includes(transactionId);

    if (isSelected) {
      setSelectedTransactions((prev) =>
        prev.filter((id) => id !== transactionId)
      );
      setPayableTotalAmount((prev) => prev - amount);
    } else {
      setSelectedTransactions((prev) => [...prev, transactionId]);
      setPayableTotalAmount((prev) => prev + amount);
    }
  };

  const handleDfcChange = (value) => {
    setDfcId(value);  // Update the selected dfcId
  };

  return (
    <>
      <Headernew />
      <div
        style={{
          padding: "20px",
          maxWidth: "800px",
          margin: "auto",
          border: "1px solid #ccc",
          borderRadius: "10px",
          marginTop: "50px",
        }}
      >
        <h2>Payment Page</h2>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div style={{ marginBottom: "20px" }}>
              <label>Select DFC Number</label><br></br>
              <Select
                value={dfcId || ""}  // Set dfcId as default
                style={{ width: "27%" }}
                onChange={handleDfcChange}
                placeholder="Select a DFC No"  
              >
                {Array.from(
                  new Set(TransactionList.map((transaction) => transaction.dfcNumber))
                ).map((uniqueDfcNumber) => (
                  <Select.Option key={uniqueDfcNumber} value={uniqueDfcNumber}>
                    {uniqueDfcNumber}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <form>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>DFC ID</th>
                    <th>Detail</th>
                    <th>UserName</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {TransactionList && TransactionList.length > 0 ? (
                    TransactionList.filter(
                      (transaction) => transaction.dfcNumber === dfcId
                    ).map((transaction) => {
                      const uniqueId = transaction.user
                        ? `${transaction._id}-${transaction.user._id}`
                        : transaction._id;

                      const isChecked = selectedTransactions.includes(uniqueId);

                      return (
                        <tr key={uniqueId}>
                          <td>
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              checked={isChecked}
                              onChange={() =>
                                toggleMemberSelection(
                                  uniqueId,
                                  transaction.amount
                                )
                              }
                              disabled={transaction.dfcNumber !== firstDfcId} // Disable checkbox for non-first DFCs
                            />
                          </td>
                          <td>{transaction.dfcNumber}</td>
                          <td>Dfc Payment</td>
                          <td>{`${transaction.user.firstname} ${transaction.user.lastname}`}</td>
                          <td>{transaction.amount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No transactions found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </form>
          </>
        )}

        {TransactionList && TransactionList.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                padding: "10px",
                borderTop: "1px solid #ccc",
              }}
            >
              <span>
                <strong>Online Payment Charges:</strong>
              </span>
              <span>
                <strong>{paymentCharges.toFixed(2)}</strong>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                padding: "10px",
                borderTop: "1px solid #ccc",
              }}
            >
              <span>
                <strong>Total Amount:</strong>
              </span>
              <span>
                <strong>{finalPayment.toFixed(2)}</strong>
              </span>
            </div>
          </>
        )}

        <UserPaymentCheck
          payableTotalAmount={finalPayment}
          userId={userId}
          groupIds={selectedTransactions}
        />
      </div>
      <Footernew />
    </>
  );
}

export default PaymentPage;
