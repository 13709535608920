import React, { useRef, useEffect, useState } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Component/Reusable/Loader/Loader";
import { Button, Divider, Form, Input, Row, Col, Switch } from "antd";
import { getSetting } from "../../Action/GetSetting";
import { addSetting } from "../../Action/AddSetting";

const Setting = () => {
  const settingdata = useSelector((state) => state?.SettingReducer);
  const addsettingdata = useSelector((state) => state?.AddSettingReducer);
  const setting = settingdata?.settingState;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isMaintainceChargeEnabled, setIsMaintainceChargeEnabled] = useState(false);

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  useEffect(() => {
    if (settingdata.success) {
      // Set the form values including Maintaince_charge_status
      form.setFieldsValue({
        trust_address_line_1: setting.trust_address_line_1,
        trust_address_line_2: setting.trust_address_line_2,
        trust_city: setting.trust_city,
        trust_pin_code: setting.trust_pin_code,
        trust_mobile_number: setting.trust_mobile_number,
        trust_open_time: setting.trust_open_time,
        trust_close_time: setting.trust_close_time,
        contact_address_line_1: setting.contact_address_line_1,
        contact_address_line_2: setting.contact_address_line_2,
        contact_city: setting.contact_city,
        contact_pin_code: setting.contact_pin_code,
        contact_mobile_number: setting.contact_mobile_number,
        dfc_month: setting.dfc_month,
        dfc_late_month: setting.dfc_late_month,
        dfc_fees: setting.dfc_fees,
        dfc_late_fees: setting.dfc_late_fees,
        dfc_cheque_days: setting.dfc_cheque_days,
        DFC_start_number: setting.DFC_start_number,
        DFC_pdf_member_list: setting.DFC_pdf_member_list,
        Receipt_start_number: setting.Receipt_start_number,
        Maintaince_charge: setting.Maintaince_charge,
        Maintaince_charge_status: setting.Maintaince_charge_status === "ENABLE",
      });
      setIsMaintainceChargeEnabled(setting.Maintaince_charge_status === "ENABLE");
    }
  }, [settingdata.success]);

  const handleToggleChange = (checked) => {
    setIsMaintainceChargeEnabled(checked);
  };

  const handleFormSubmit = (params) => {
    let payload = {
      trust_address_line_1: params?.trust_address_line_1,
      trust_address_line_2: params?.trust_address_line_2,
      trust_city: params?.trust_city,
      trust_pin_code: params?.trust_pin_code?.toString(),
      trust_mobile_number: params?.trust_mobile_number?.toString(),
      trust_open_time: params?.trust_open_time?.toString(),
      trust_close_time: params?.trust_close_time?.toString(),
      contact_address_line_1: params?.contact_address_line_1,
      contact_address_line_2: params?.contact_address_line_2,
      contact_city: params?.contact_city,
      contact_pin_code: params?.contact_pin_code?.toString(),
      contact_mobile_number: params?.contact_mobile_number?.toString(),
      dfc_month: params?.dfc_month?.toString(),
      dfc_late_month: params?.dfc_late_month?.toString(),
      dfc_fees: params?.dfc_fees?.toString(),
      dfc_late_fees: params?.dfc_late_fees?.toString(),
      dfc_cheque_days: params?.dfc_cheque_days?.toString(),
      DFC_start_number: params?.DFC_start_number,
      DFC_pdf_member_list: params?.DFC_pdf_member_list,
      Receipt_start_number: params?.Receipt_start_number,
      Maintaince_charge: params?.Maintaince_charge,
      Maintaince_charge_status: isMaintainceChargeEnabled ? "ENABLE" : "DISABLE",
    };

    // Dispatch the action to save the settings
    dispatch(addSetting(payload));
  };

  const onFinishFailed = () => {};

  return (
    <SiteLayout>
      {settingdata.settingloading && <Loader />}
      {addsettingdata.settingloading && <Loader />}
      <div className="subscription-container">
        <Row gutter={2}>
          <Col
            xxl={12}
            xl={12}
            lg={20}
            md={20}
            sm={24}
            xs={24}
            style={{ margin: "0 auto" }}
          >
            <div
              style={{ padding: "20px", margin: "auto" }}
              className="gig-sure-card-wrapper"
            >
              <Divider className="divider">Trust Setting</Divider>

              <Form
                scrollToFirstError={true}
                onFinish={handleFormSubmit}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  Maintaince_charge_status: isMaintainceChargeEnabled,
                }}
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="Horizontal"
                form={form}
                name="setting"
                autoComplete="off"
              >
                <Row gutter={0}>
                  <Col xl={18} lg={18} md={18} sm={21} xs={21} offset={3}>
                    {/* address 1 */}
                    <Form.Item
                      name="trust_address_line_1"
                      label="Address line 1"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Trust Address line 1 is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* address 2 */}
                    <Form.Item
                      name="trust_address_line_2"
                      label="Address line 2"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Trust Address line 1 is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* city */}
                    <Form.Item
                      name="trust_city"
                      label="City"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* pin  */}
                    <Form.Item
                      name="trust_pin_code"
                      label="Pin code"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Pin code is required",
                        },
                        {
                          pattern: /^\d{6}$/,
                          message: "Please Insert Valid Pin Code",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* mobile */}
                    <Form.Item
                      name="trust_mobile_number"
                      label="Mobile number"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Mobile number is required",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Please Insert Valid Mobile Number",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* open */}
                    <Form.Item
                      name="trust_open_time"
                      label="Open time"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Open time is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* close */}
                    <Form.Item
                      name="trust_close_time"
                      label="Close time"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Close time is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider className="divider">Contact Setting</Divider>

                <Row gutter={0}>
                  <Col xl={18} lg={18} md={18} sm={21} xs={21} offset={3}>
                    {/* address 1 */}
                    <Form.Item
                      name="contact_address_line_1"
                      label="Address line 1"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Contact Address line 1 is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* address 2 */}
                    <Form.Item
                      name="contact_address_line_2"
                      label="Address line 2"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Trust Address line 1 is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* city */}
                    <Form.Item
                      name="contact_city"
                      label="City"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* pin  */}
                    <Form.Item
                      name="contact_pin_code"
                      label="Pin code"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Pin code is required",
                        },

                        {
                          pattern: /^\d{6}$/,
                          message: "Please Insert Valid Pin Code",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* mobile */}
                    <Form.Item
                      name="contact_mobile_number"
                      label="Mobile number"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Mobile number is required",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Please Insert Valid Mobile Number Id",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider className="divider">DFC Setting</Divider>

                <Row gutter={0}>
                  <Col xl={18} lg={18} md={18} sm={21} xs={21} offset={3}>
                    {/* month */}
                    <Form.Item
                      name="dfc_month"
                      label="Month"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Month is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* late month */}
                    <Form.Item
                      name="dfc_late_month"
                      label="Late month"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Late month is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* Fees */}
                    <Form.Item
                      name="dfc_fees"
                      label="Fees"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Fees is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* late Fees */}
                    <Form.Item
                      name="dfc_late_fees"
                      label="Late Fees"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Late Fees is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* cheque day */}
                    <Form.Item
                      name="dfc_cheque_days"
                      label="Cheque days"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Cheque days is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* DFC_start_number */}
                    <Form.Item
                      name="DFC_start_number"
                      label="DFC start number"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "DFC_start_number is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    {/* Receipt_start_number */}
                    <Form.Item
                      name="Receipt_start_number"
                      label="Receipt start number"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Receipt_start_number is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                    
                    {/* DFC_pdf_member_list */}
                    <Form.Item
                      name="DFC_pdf_member_list"
                      label="DFC PDF member list"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "DFC PDF member list is required",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Only numbers are allowed",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>

                    <Form.Item
          name="Maintaince_charge"
          label="Yearly Maintaince Fees:"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          rules={[
            {
              required: true,
              message: "Yearly_Maintaince_charge is required",
            },
            {
              pattern: /^\d+$/,
              message: "Only numbers are allowed",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        {/* Toggle to Enable/Disable Maintaince Charge */}
        <Form.Item
          label="Maintaince Fees"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
        >
          <Switch
            checked={isMaintainceChargeEnabled}
            onChange={handleToggleChange} // Toggle handler
          />
        </Form.Item>


          
                  </Col>
                </Row>

                <Row>
                  <Col span={23} style={{ textAlign: "end" }}>
                    <Form.Item className="submit">
                      <Button
                        size="large"
                        className="bg-primary"
                        style={{ marginTop: "12px" }}
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
};

export default Setting;
