/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState, } from "react";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { connect, useSelector } from "react-redux";
import "../Members/style.css";
import { SearchOutlined } from '@ant-design/icons';
import Loader from "../../Component/Reusable/Loader/Loader";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Row,
    Col,
    Descriptions,
    PageHeader,
} from "antd";

import { findMemberId, initializeState, fetchdeathmember } from "../../Action/Death"
import { addDeathMember } from "../../Action/AddDeath"

const converter = require('number-to-words');



// Action
const mapDispatch = (dispatch) => {
    return {
        // call action
        resetState: () => dispatch(initializeState()),
        findMember: (payload) => dispatch(findMemberId(payload)),
        addMemberDeath: (payload) => dispatch(addDeathMember(payload)),
        fetchMemberDeath: (id) => dispatch(fetchdeathmember(id))

    };
};

const mapState = (props) => ({
    SelectLoader: props?.DeathReducer?.isloading,
    Loader: props?.AddDeathReducer?.loading,
    fetchiddeath: props?.DeathReducer?.deathbyid,
});

function Death(store) {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    let navigate = useNavigate();
    const { id } = useParams();

    const [memberId, setMemberId] = useState('');
    const [membercheck, setmembercheck] = useState(true);
    const [reset, setReset] = useState(false);
    const [amount, seAmount] = useState("");
    const [member, setMember] = useState(false);


    useEffect(() => {
        const payload = {
            id
        }
        id && store?.fetchMemberDeath(payload);
        // console.log("id----=-", store?.fetchiddeath);
        // eslint-disable-next-line
    }, [id, store?.fetchMemberDeath]);

    useEffect(() => {
        if (id) {
            if (store?.fetchiddeath) {
                const memberName = `${store?.fetchiddeath?.refmemberId.firstname} ${store?.fetchiddeath?.refmemberId.middlename} ${store?.fetchiddeath?.refmemberId.lastname}`;
                setMember(memberName);
                form.setFieldsValue({
                    MemberId: store?.fetchiddeath?.memberId,
                    Payableamount: store?.fetchiddeath?.payableamount,
                    City: store?.fetchiddeath?.deathCity,
                    DeathDate: moment(
                        new Date(store?.fetchiddeath?.DeathOnUTC)?.toLocaleDateString(),
                        "DD-MM-YYYY"
                    ),
                    InformDate: moment(
                        new Date(store?.fetchiddeath?.InformOnUTC)?.toLocaleDateString(),
                        "DD-MM-YYYY"
                    ),
                });
            }
        }
    }, [store?.fetchiddeath, id, form]);


    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };



    // const [wordamount, setwordamount] = useState("");

    const changeAmount = (e) => {
        seAmount(e.target.value);
        // setwordamount(converter.toWords(e.target.value))
        // console.log('wordamount: ', wordamount);
    }

    const searchMember = () => {
        let payload = {
            MemberId: memberId
        }
        store.findMember(payload);
    }

    const deathmemberdetail = useSelector(state => state.DeathReducer);
    const adddeathdetail = useSelector(state => state.AddDeathReducer);


    useEffect(() => {
        if (deathmemberdetail.success) {
            if (!id) {
                form.setFieldsValue({
                    Member: deathmemberdetail?.deathmember[0],
                    City: deathmemberdetail?.deathmember[1],
                });

                setMember(deathmemberdetail?.deathmember[0]);
            }

        }
        else {
            form.setFieldsValue({
                // Member: null,
                // City: null,
            });
            // setMember(false);
        }

        if (adddeathdetail.success) {
            if (!reset) {
                form.resetFields();
                store.resetState();
                setMember(false);
                setReset(true)
            }
        }

    }, [deathmemberdetail, adddeathdetail, reset, form, store,])

    const handleFormSubmit = (params) => {
        let payload = {
            memberid: params?.MemberId,
            deathdate: params?.DeathDate,
            informdate: params?.InformDate,
            member: params?.Member,
            membercity: params?.City,
            membermobileno: params?.MobileNo,
            payableamount: params?.Payableamount,
        }

        setReset(false);
        store.addMemberDeath(payload);
    }

    const onMemberIdChange = (e) => {
        form.setFieldsValue({
            //Member: null,
            City: null,
        });

        setMember(false);
        setMemberId(e.target.value);
        const reg = new RegExp('^[0-9]*$');
        if (reg.test(e.target.value) === false) {
            setmembercheck(false)
        }
        else {
            setmembercheck(true)
        }
    };

    return (
        <SiteLayout>
            {store?.SelectLoader === true && <Loader />}
            {store?.Loader === true && <Loader />}
            <div className="headingDiv">
                <h2 style={{ textAlign: "center", margin: "0px" }}>
                    Add Death
                </h2>
            </div>

            <div className="subscription-container center-content">
                <Row gutter={2}>
                    <Col
                        //changes start
                        xxl={16}
                        xl={16}
                        lg={22}
                        md={22}
                        sm={24}
                        xs={24}
                        style={{ margin: "0 auto" }}
                    //changes end
                    >
                        <div className="pgheader">
                            <Row>
                                <Col xs={24} xl={24}>
                                    <PageHeader
                                        onBack={() => navigate(-1)}
                                        title="Go Back"
                                    ></PageHeader>
                                </Col>
                            </Row>
                        </div>
                        <div
                            style={{ padding: "30px" }}
                            className="checkout-form-container gig-sure-card-wrapper"
                        >

                            <Form
                                scrollToFirstError={true}
                                onFinish={handleFormSubmit}
                                onFinishFailed={onFinishFailed}
                                layout="vertical"
                                form={form}
                                name="dfc"
                                autoComplete="off"
                                ref={formRef}
                            >
                                <Row gutter={0}>
                                    <Col xl={16} lg={21} md={21} sm={21} xs={21} style={{ marginRight: '10px' }}>
                                        <Form.Item
                                            name="MemberId"
                                            label="Member Id #"
                                            className="field-with-prefix"
                                            // onPressEnter={() => formRef.current.click()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Member Id is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Member Id",
                                                },
                                            ]}
                                        >

                                            <Input
                                                //addonBefore={prefixSelector}
                                                autoComplete="off"
                                                placeholder="Member Id"
                                                value={memberId}
                                                onChange={onMemberIdChange}
                                            />

                                        </Form.Item >
                                    </Col>
                                    <Col span={3}>
                                        {memberId === '' || membercheck === false ?
                                            <Button icon={<SearchOutlined />}
                                                style={{ marginTop: "30px", height: "52px", width: "130px" }}
                                                size="large"
                                                disabled={true}
                                            >
                                                Search
                                            </Button>
                                            :
                                            <Button icon={<SearchOutlined />}
                                                style={{ marginTop: "30px", height: "52px", width: "130px", background: "rgb(16 3 72)", color: "white" }}
                                                size="large"
                                                // ref={formRef}
                                                onClick={searchMember}
                                                disabled={false}
                                            >
                                                Search
                                            </Button>
                                        }
                                    </Col>
                                </Row>

                                <Row gutter={10}>
                                    <Col xl={20} lg={20} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            name="Member"
                                            label="Member #"

                                        >
                                            <Descriptions size="middle">
                                                <Descriptions.Item>
                                                    {member === false ?
                                                        <i style={{ color: "grey", margin: "0px" }}>No member available</i> :
                                                        <p style={{ fontSize: "20px", margin: "0px" }}>{member}</p>
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={10}>

                                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="City"
                                            label="City #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "City is required",
                                                }
                                            ]}
                                        >
                                            <Input
                                                autoComplete="off"
                                                placeholder="City"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="Payableamount"
                                            label="Payable amount #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Payable amount is required",
                                                },
                                                {
                                                    pattern: /^(?:\d*)$/,
                                                    message: "Please Insert Valid Payable amount",
                                                },
                                            ]}
                                        >
                                            <Input
                                                autoComplete="off"
                                                placeholder="Payable amount"
                                                onChange={changeAmount}
                                                value={amount}
                                            />

                                        </Form.Item>
                                    </Col>

                                    {/* <Col xl={6} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="Payableamountword"
                                            label="Payable amount in word #"
                                            className="field-with-prefix"
                                        >
                                            <Input
                                                autoComplete="off"
                                                placeholder="Payable amount in word"
                                                value={wordamount}
                                            />

                                        </Form.Item>
                                    </Col> */}

                                    <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name="DeathDate"
                                            label="Death Date #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Death Date is required",
                                                }
                                            ]}
                                        >
                                            <DatePicker
                                                placeholder="Death date"
                                                disabledDate={(current) => current && current.isAfter(new Date())}
                                                style={{ width: "100%" }}
                                                format="DD-MM-YYYY"
                                            />

                                        </Form.Item>
                                    </Col>

                                    <Col xl={6} lg={12} md={13} sm={24} xs={24}>
                                        <Form.Item
                                            name="InformDate"
                                            label="Inform Date #"
                                            className="field-with-prefix"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Inform Date is required",
                                                }
                                            ]}
                                        >

                                            <DatePicker
                                                placeholder="Inform date"
                                                style={{ width: "100%" }}
                                                disabledDate={(current) => current && current.isAfter(new Date())}
                                                format="DD-MM-YYYY"
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={1} style={{ textAlign: "end" }}>
                                        <Form.Item className="submit">
                                            <Button
                                                size="large"
                                                className="bg-primary"
                                                style={{ marginTop: '12px' }}
                                                // type="success"
                                                htmlType="submit"
                                            // loading={isLoading}
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Form>

                        </div>
                    </Col>
                </Row>
            </div>
        </SiteLayout >
    );
}

export default connect(mapState, mapDispatch)(Death);
