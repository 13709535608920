import React, { useEffect, useState } from "react";
import { Table, Space, message, Row, Col, Button, Checkbox, Input } from "antd";
import Loader from "../Reusable/Loader/Loader";
import SiteLayout from "../Reusable/Layout/SiteLayout";
import api from "../../Config/Api";

const Dfcpending = () => {
  const [unpaidUsers, setUnpaidUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState(0);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [selectAll, setSelectAll] = useState(false);  // New state to handle "select all" functionality

  // Function to handle checkbox selection

  const handleCheckboxChange = (record, checked) => {
    if (checked) {
      setSelectedUserIds([...selectedUserIds, record._id]);
    } else {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== record._id));
    }
  };

  // Handle select all checkbox change
  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedUserIds(unpaidUsers.map((user) => user._id));  // Select all users
    } else {
      setSelectedUserIds([]);  // Deselect all users
    }
  };

  const handleSearch = async (e) => {
    if (e) e.preventDefault();
    if (searchValue.trim()) {
      try {
        setLoading(true);
        const response = await api.get(`/admin/getUnpaidUsers`, {
          params: {
            dfcNumber: searchValue.trim(),
            page: currentPage,
            size: pageSize,
          },
        });
  
        if (response.status === 200 && response.data.data.length > 0) {
          setUnpaidUsers(response.data.data);
          setTotal(response.data.total); 
          setSelectedUserIds(response.data.data.map(user => user._id));
          setIsSearchPerformed(true);
          message.success("Unpaid users fetched successfully.");
        } else {
          setUnpaidUsers([]);
          setTotal(0);  // Set total to 0 when no users found
          setSelectedUserIds([]);
          setIsSearchPerformed(true);
          message.error("No unpaid users found with this DFC number.");
        }
      } catch (error) {
        console.error("Error fetching unpaid users:", error);
        message.error("An error occurred while fetching unpaid users.");
      } finally {
        setLoading(false);
      }
    } else {
      setIsSearchPerformed(false);
      setUnpaidUsers([]);
      setTotal(0);  // Reset total count when search is cleared
      setSelectedUserIds([]);
    }
  };
  
  // Function to send SMS to selected users
const handleSendSMS = async () => {
  if (selectedUserIds.length > 0 && searchValue.trim()) {
    try {
      // Sending SMS with selected user IDs and the searched DFC number
      const response = await api.post("/admin/sendSMS", {
        userIds: selectedUserIds,
        dfcNumber: searchValue.trim(), // Add the searched DFC number here
      });

      if (response.status === 200) {
        message.success(`SMS sent to ${selectedUserIds.length} user(s).`);
      } else {
        message.error("Failed to send SMS.");
      }
    } catch (error) {
      console.error("Error sending SMS:", error);
      message.error("An error occurred while sending SMS.");
    }
  } else {
    message.warning("Please select at least one user and ensure a DFC number is entered.");
  }
};

  // Table columns definition
  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       checked={selectAll}
    //       onChange={handleSelectAllChange}  
    //     />
    //   ),
    //   key: "selectAll",
    //   render: () => null,  // No content in this column, just the checkbox
    // },

    {
      title: "Member No",
      dataIndex: "memberNo",
      key: "memberNo",
    },
    {
      title: "DFC Number",
      dataIndex: "dfcNumber",
      key: "dfcNumber",
      render: (text) => (text ? text : "N/A"),
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Middle Name",
      dataIndex: "middlename",
      key: "middlename",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber1",
      key: "mobileNumber1",
    },
    {
      title: "Send SMS",
      key: "sendSMS",
      render: (_, record) => (
        <Checkbox
          checked={selectedUserIds.includes(record._id)}  // Check if user ID is in the selectedUserIds array
          onChange={(e) => handleCheckboxChange(record, e.target.checked)}
        />
      ),
    },
  ];

  // Handle table pagination change
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    handleSearch(); // Refetch data when page or size changes

  };

  return (
    <SiteLayout>
      {loading && <Loader />}
      <div
        style={{ padding: "30px" }}
        className="checkout-form-container gig-sure-card-wrapper"
      >
        <div className="wrapper_twoDiv">
          <Space style={{ marginBottom: 18 }}>
            <h3 className="start">DFC Pending List</h3>
          </Space>


          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col>
              <Input
                placeholder="Enter DFC Number"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ width: 300 }}
              />
              <Button
                type="primary"
                onClick={(e) => handleSearch(e)}  // Pass the event object
                style={{ marginLeft: 10 }}
              >
                Search
              </Button>
            </Col>
          </Row>

                    <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Col>
              <span>
                <strong>Total Pending Members: </strong>
                {unpaidUsers.length}
              </span>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={selectedUserIds.length === 0}
                onClick={handleSendSMS}
              >
                Send SMS
              </Button>
            </Col>
          </Row>


          <Table
            dataSource={unpaidUsers}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,

              pageSizeOptions: [5, 10, 15],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />

        </div>
      </div>
    </SiteLayout>
  );
};

export default Dfcpending;
