import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function Headernew() {
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const showAdditionalItem =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/contact-us";

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header
        id="header"
        className="header-size-sm border-bottom-0"
        data-sticky-shrink="false"
        style={{ position: "relative" }}
      >
        <div id="header-wrap">
          <div className="container">
            <div className="header-row justify-content-lg-between">
              <div id="logo" className="me-lg-5">
                <a href="/">
                  <img
                    className="logo-default"
                    srcSet="images/logo.png"
                    src="demos/business/images/logo@2x.png"
                    alt="Canvas Logo"
                  />
                  <img
                    className="logo-dark"
                    srcSet="demos/business/images/logo-dark.png, demos/business/images/logo-dark@2x.png 2x"
                    src="demos/business/images/logo-dark@2x.png"
                    alt="Canvas Logo"
                  />
                </a>
              </div>
              <div className="header-misc">
                <a
                  href="/user-login"
                  className="button button-rounded button-light"
                >
                  <div>Pay DFC</div>
                </a>
              </div>
              <div className="primary-menu-trigger">
                <button
                  className="cnvs-hamburger"
                  type="button"
                  title="Open Mobile Menu"
                  onClick={handleMobileMenuToggle}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <span className="cnvs-hamburger-box">
                    <span className="cnvs-hamburger-inner" />
                  </span>
                </button>
              </div>
              <nav className="primary-menu with-arrows me-lg-auto">
                <ul className="menu-container align-self-start">
                  {showAdditionalItem && (
                    <li className="menu-item">
                      <span className="menu-bg col-auto align-self-start d-flex" />
                    </li>
                  )}
                  <li
                    className={`menu-item ${
                      location.pathname === "/" ? "current" : ""
                    }`}
                  >
                    <a className="menu-link" href="/">
                      <div>Home</div>
                    </a>
                  </li>
                  <li
                    className={`menu-item ${
                      location.pathname === "/about-us" ? "current" : ""
                    }`}
                  >
                    <a className="menu-link" href="/about-us">
                      <div>About Us</div>
                    </a>
                  </li>
                  <li
                    className={`menu-item ${
                      location.pathname === "/contact-us" ? "current" : ""
                    }`}
                  >
                    <a className="menu-link" href="/contact-us">
                      <div>Contact Us</div>
                    </a>
                  </li>
                  <li
                    className={`menu-item ${
                      location.pathname === "/login" ? "current" : ""
                    }`}
                  >
                    <a className="menu-link" href="/login">
                      <div>Login</div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="header-wrap-clone" />
        {/* Mobile Menu Dropdown */}
        {isMobileMenuOpen && (
          <div
            style={{
              position: "absolute",
              top: "60px",
              right: "0",
              width: "100%",
              backgroundColor: "#fff",
              borderTop: "1px solid #ddd",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ul
              style={{
                listStyle: "none",
                margin: "0",
                padding: "0",
                width: "100%",
                textAlign: "center",
              }}
            >
              <li style={{ margin: "10px 0" }}>
                <a
                  className={`menu-link ${
                    location.pathname === "/" ? "current" : ""
                  }`}
                  href="/"
                  style={{
                    textDecoration: "none",
                    color: location.pathname === "/" ? "#7765AA" : "#333",
                  }}
                >
                  <div>Home</div>
                </a>
              </li>
              <li style={{ margin: "10px 0" }}>
                <a
                  className={`menu-link ${
                    location.pathname === "/about-us" ? "current" : ""
                  }`}
                  href="/about-us"
                  style={{
                    textDecoration: "none",
                    color:
                      location.pathname === "/about-us" ? "#7765AA" : "#333",
                  }}
                >
                  <div>About Us</div>
                </a>
              </li>
              <li style={{ margin: "10px 0" }}>
                <a
                  className={`menu-link ${
                    location.pathname === "/contact-us" ? "current" : ""
                  }`}
                  href="/contact-us"
                  style={{
                    textDecoration: "none",
                    color:
                      location.pathname === "/contact-us" ? "#7765AA" : "#333",
                  }}
                >
                  <div>Contact Us</div>
                </a>
              </li>
              <li style={{ margin: "10px 0" }}>
                <a
                  className={`menu-link ${
                    location.pathname === "/login" ? "current" : ""
                  }`}
                  href="/login"
                  style={{
                    textDecoration: "none",
                    color: location.pathname === "/login" ? "#7765AA" : "#333",
                  }}
                >
                  <div>Login</div>
                </a>
              </li>
            </ul>
          </div>
        )}
      </header>
    </>
  );
}

export default Headernew;
