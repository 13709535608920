import {
  TRANSACTION_LIST,
  TRANSACTION_LOADING,
  INITIALIZE_STATE,
} from "./ActionTypes";
import api from "../Config/Api";
import { message } from "antd";

// Select List
// export const CheckUserTransaction = () => {
//   return (dispatch) => {
//     dispatch(loader(true));
//     try {
//       api({
//         url: `admin/users/transaction`,
//         method: "get",
//         params: {
//           // userId: "6662ee5a000f7139895e4bac",
//           userId: "6662ed90000f7139895e4b95",
//         },
//       }).then(
//         (response) => {
//           let data = response.data.data;
//           let responseStatus = response.data.code;

//           if (responseStatus === 200) {
//             dispatch({
//               type: TRANSACTION_LIST,
//               payload: data.filter((item) => !item.transaction),
//             });
//             dispatch(loader(false));
//           }
//         },
//         function (error) {
//           errorMessageConfig(dispatch);
//         }
//       );
//     } catch (err) {
//       errorMessageConfig(dispatch);
//     }
//   };
// };

export const CheckUserTransaction = () => {
  return (dispatch) => {
    dispatch(loader(true));

    // Get userId from localStorage
    const userId = localStorage.getItem("userId");

    if (!userId) {
      dispatch(loader(false));
      message.error("User ID is missing");
      return;
    }

    try {
      api({
        url: `admin/users/transaction`,
        method: "get",
        params: { userId }, // Use the userId from localStorage
      }).then(
        (response) => {
          console.log("Response Data:", response.data); // Log the entire response data
          let data = response.data.data;
          let responseStatus = response.data.code;

          if (responseStatus === 200) {
            console.log("Transaction Data:", data); // Log the data to see its structure
            dispatch({
              type: TRANSACTION_LIST,
              payload: Array.isArray(data)
                ? data.filter((item) => !item.transaction)
                : [], // Ensure data is an array
            });
            dispatch(loader(false));
          }
        },
        function (error) {
          errorMessageConfig(dispatch);
        }
      );
    } catch (err) {
      errorMessageConfig(dispatch);
    }
  };
};

function errorMessageConfig(dispatch) {
  dispatch(loader(false));
  message.error("Something Went Wrong");
}

export const loader = (para) => {
  return {
    type: TRANSACTION_LOADING,
    payload: para,
  };
};
